import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import Message from 'components/content/Message';
export const _frontmatter = {
  "path": "/developer/javascript/isomorphic-javascript",
  "date": "2015-09-11",
  "title": "ISOMORPHIC JAVASCRIPT",
  "author": "admin",
  "tags": ["development", "javascript"],
  "featuredImage": "feature.jpg",
  "excerpt": "Isomorphic JavaScript means that we get to share the same code on the client and on the server. So basically, you are rendering your application markup on the server and piping it down as complete HTML to the browser."
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <h2>{`Why Isomorphism?`}</h2>
    <p>{`The reasoning behind an isomrophic app is that instead of bundling all your `}<inlineCode parentName="p">{`React`}</inlineCode>{` code into a single bundle (and then using something like `}<strong parentName="p">{`<`}{`script src="bundle.js"`}{`>`}</strong>{` in a bare bones HTML page), you run a Node.js server that serves the files for you.`}</p>
    <p>{`Rendered in server the app can simply display that rendered string to the user when they first visit the page. When the user visits the page, that rendered string gets overwritten after the bundle is downloaded and then the React app runs like normal.`}</p>
    <Message type="warn" title="" content="JavaScript driven MVCs (angular, ember, backbone, etc.) render on DOM load, this can be really slow and can make for a bad user experience. And they are not indexable by search engines." mdxType="Message" />
    <p>{`So the Isomorphic JavaScript will:`}</p>
    <ul>
      <li parentName="ul">{`Render HTML from JavaScript app on the server `}</li>
      <li parentName="ul">{`Browser loads with full HTML and JavaScript `}</li>
      <li parentName="ul">{`Bootstraps the app. `}</li>
    </ul>
    <h2>{`Benefits of Isomorphic JavaScript`}</h2>
    <ul>
      <li parentName="ul">{`Faster Perceived Load Times `}</li>
      <li parentName="ul">{`Better overall user experience `}</li>
      <li parentName="ul">{`Search Engine Indexability `}</li>
      <li parentName="ul">{`Progressive Enhancements `}</li>
      <li parentName="ul">{`Easier Code Maintenance `}</li>
    </ul>
    <h2>{`Installation`}</h2>
    <p>{`Install Node.js (atleast 0.12).`}</p>
    <p>{`Create a directory for your project and initialise a `}<strong parentName="p">{`package.json`}</strong>{` file by running:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-sh"
      }}>{`npm init
`}</code></pre>
    <p>{`Now let's install the necessary packages by running the following command:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-sh"
      }}>{`npm install --save-dev babel babel-loader express jade react react-hot-loader react-router webpack webpack-dev-server nodemon
`}</code></pre>
    <p>{`After the installation, create your directory structure as follows:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-sh"
      }}>{`mkdir src src/server src/shared src/client views
`}</code></pre>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`server`}</inlineCode>{`  - Holds the backend Node server and Webpack dev server `}</li>
      <li parentName="ul"><inlineCode parentName="li">{`client`}</inlineCode>{` - Holds the React bundle `}</li>
      <li parentName="ul"><inlineCode parentName="li">{`shared`}</inlineCode>{` - Hold your components, flux, and routes `}</li>
    </ul>
    <h2>{`HTML view`}</h2>
    <p>{`Create a file called `}<strong parentName="p">{`index.html`}</strong>{` in the views folder, and paste the following:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`<html>
  <head>
    <title>Isomorphic JavaScript</title>
    <meta charset="utf-8"/>
    <meta http-equiv="X-UA-Compatible" content="IE=edge"/>
    <meta name="description" content=""/>
    <meta name="viewport" content="width=device-width, initial-scale=1"/>
  </head>
  <body>
    <div id="app">\`\`\`
  </body>
  <script src="http://localhost:8080/js/app.js" defer="defer"></script>
</html>
`}</code></pre>
    <h2>{`Webpack`}</h2>
    <p>{`Create a new file called `}<strong parentName="p">{`webpack.js`}</strong>{` in `}<strong parentName="p">{`src/server/`}</strong>{`:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`import WebpackDevServer from "webpack-dev-server";
import webpack from "webpack";
import config from "../../webpack.config.dev";

var server = new WebpackDevServer(webpack(config), {
  // webpack-dev-server options
  publicPath: config.output.publicPath,
  hot: true,
  stats: { colors: true },
});

server.listen(8080, "localhost", function() {});
`}</code></pre>
    <p>{`Create `}<strong parentName="p">{`webpack.config.dev.js`}</strong>{` in the root of your folder:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`var webpack = require('webpack');

module.exports = {
  devtool: 'inline-source-map',
  entry: [
    'webpack-dev-server/client?http://localhost:8080',
    'webpack/hot/only-dev-server',
    './src/client/entry',
  ],
  output: {
    path: __dirname + '/public/js/',
    filename: 'app.js',
    publicPath: 'http://localhost:8080/js/',
  },
  plugins: [
    new webpack.HotModuleReplacementPlugin(),
    new webpack.NoErrorsPlugin(),
  ],
  resolve: {
    extensions: ['', '.js']
  },
  module: {
    loaders: [
      {
        test: /\\.jsx?$/,
        loaders: ['react-hot', 'babel-loader?experimental'],
        exclude: /node_modules/
      }
    ]
  }
}
`}</code></pre>
    <h2>{`Node.js`}</h2>
    <p>{`Create a new file in `}<strong parentName="p">{`src/server`}</strong>{` called `}<strong parentName="p">{`server.js`}</strong>{`:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`import express from "express";
import React from "react";
import Router from "react-router";
const app = express();

import routes from "../shared/routes";

app.get('/*', function (req, res) {
  Router.run(routes, req.url, Handler => {
    let content = React.renderToString(<Handler />);
    res.render('index', { content: content });
  });
});

var server = app.listen(3000, function () {
  var host = server.address().address;
  var port = server.address().port;

  console.log('Example app listening at http://%s:%s', host, port);
});
`}</code></pre>
    <h2>{`Client`}</h2>
    <p>{`Create `}<strong parentName="p">{`src/client/entry.js`}</strong>{` and import `}<strong parentName="p">{`react-router`}</strong>{`:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`import React from "react";
import Router from "react-router";
import routes from "../shared/routes";

Router.run(routes, Router.HistoryLocation, (Handler, state) => {
  React.render(<Handler />, document.getElementById('app'));
});
`}</code></pre>
    <h2>{`Routes`}</h2>
    <p>{`Create `}<strong parentName="p">{`routes.js`}</strong>{` in `}<strong parentName="p">{`/src/shared`}</strong>{` which is the link that is combining our server and client:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`import { Route } from "react-router";
import React from "react";

import AppHandler from "./components/AppHandler";

export default (
  <Route handler={ AppHandler } path="/" />
);
`}</code></pre>
    <h2>{`Running the server`}</h2>
    <p>{`Run following command from root directory:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-sh"
      }}>{` npm start
`}</code></pre>
    <p>{`This will run a watch task for Babel to handle the es6, webpack-dev-server to hot reloading, and the Node server.`}</p>
    <p>{`Visit http://localhost:3000, and you should see "Hello App Handler". That's our Isomorphic App!`}</p>
    <p>{`You can find the source code in Github: `}<a parentName="p" {...{
        "href": "https://github.com/szaranger/isomorphic-javascript"
      }}>{`Isomorphic JavaScript`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      